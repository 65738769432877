// Generated by Framer (b0164a3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {pa61KkWA1: {hover: true}};

const serializationHash = "framer-NXitd"

const variantClassNames = {pa61KkWA1: "framer-v-vhapsy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.4, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({click, height, id, link, width, ...props}) => { return {...props, ctMHDCmyF: link ?? props.ctMHDCmyF, iGGj40Pv2: click ?? props.iGGj40Pv2} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

const Variants = motion.create(React.Fragment)

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ctMHDCmyF, iGGj40Pv2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "pa61KkWA1", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapbjtusi = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (iGGj40Pv2) {const res = await iGGj40Pv2(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={ctMHDCmyF} nodeId={"pa61KkWA1"} openInNewTab={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-vhapsy", className, classNames)} framer-12w2p6m`} data-framer-name={"Project Button "} data-highlight layoutDependency={layoutDependency} layoutId={"pa61KkWA1"} onTap={onTapbjtusi} ref={ref ?? ref1} style={{backgroundColor: "var(--token-f4988ed9-fbca-4b82-8f0d-f654d0e434e6, rgb(0, 0, 0))", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} {...addPropertyOverrides({"pa61KkWA1-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-xm3w3k"} data-framer-name={"Arrow Icon"} fill={"var(--token-dd95d322-8000-4206-8f77-378db76ee406, rgb(255, 255, 255)) /* {\"name\":\"White\"} */"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"ulVN9xXMW"} style={{rotate: 0}} svg={"<svg stroke=\"currentColor\" fill=\"none\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" height=\"24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M5 12h14m-7-7 7 7-7 7\"/></svg>"} variants={{"pa61KkWA1-hover": {rotate: -45}}} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NXitd.framer-12w2p6m, .framer-NXitd .framer-12w2p6m { display: block; }", ".framer-NXitd.framer-vhapsy { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 100px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 100px; will-change: var(--framer-will-change-override, transform); }", ".framer-NXitd .framer-xm3w3k { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 30px); position: relative; width: 30px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-NXitd.framer-vhapsy { gap: 0px; } .framer-NXitd.framer-vhapsy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-NXitd.framer-vhapsy > :first-child { margin-left: 0px; } .framer-NXitd.framer-vhapsy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"nlWLLLFCd":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ctMHDCmyF":"link","iGGj40Pv2":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPqgzMog5L: React.ComponentType<Props> = withCSS(Component, css, "framer-NXitd") as typeof Component;
export default FramerPqgzMog5L;

FramerPqgzMog5L.displayName = "Project Hover Button";

FramerPqgzMog5L.defaultProps = {height: 100, width: 100};

addPropertyControls(FramerPqgzMog5L, {ctMHDCmyF: {title: "Link", type: ControlType.Link}, iGGj40Pv2: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerPqgzMog5L, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})